.rounded {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 5px 5px 5px #524f4f;
}
.rounded::after {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.rounded:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  cursor: pointer;

  box-shadow: 8px 8px 8px #524f4f;
}

.rounded:hover::after {
    opacity: 1;

}