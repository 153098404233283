.frame-size{
	width: 800px;
  	height: 480px;
}

.img-container {
    overflow: hidden;
    min-height:300px;
    max-height: 480px;
    position: relative;
}

.img {
    display: block;
    height: auto;
    position: relative;
    bottom: 0;
}

